// import './src/styles/global.css';
require('./src/styles/global.css')
// PUSH TO GTM on every route update
// $page_type contenant le type de page sur laquelle on se trouve 
// (exemple : "homepage", "les secteurs", "secteur", "les organisations", "organisation")
// $page_name contenant le nom complet de la page 
// (exemple : "homepage", "les secteurs", "secteur - energie", "les organisations - batiments et construction", "organisation: terrawatt")
exports.onRouteUpdate = function (_) {
    

    // var el = document.createElement('script');
    //         el.setAttribute('src', 'https://static.axept.io/sdk.js');
    //         el.setAttribute('type', 'text/javascript');
    //         el.setAttribute('async', true);
    //         el.setAttribute('data-id', '5fa56541c594b151a13d46d4');
    //         el.setAttribute('data-cookies-version', 'shiftyourjob-base');
    // if (document.body !== null) {
    //     document.body.appendChild(el);
    // }

        // RUN only in production env
    if (process.env.NODE_ENV === "production" ) {
        // wrap inside a timeout to ensure react helmet has properly run
        setTimeout(function () {
            window.dataLayer.push({
                'event':'page_view',
                'pageType': document.querySelector("meta[name='gtm:pageType']")?.getAttribute("content") || '' ,
                'pageName': document.querySelector("meta[name='gtm:pageName']")?.getAttribute("content") || '',
                'pageDescription': document.querySelector("meta[name='gtm:pageDescription']")?.getAttribute("content") || '',
            });
        }, 50);
    }
};
